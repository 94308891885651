import { EciDxQueryResult, EciDxService, EciQueryEncoder } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { EciDxDataSourceOptions } from '@shared-components/shopsys-commons-ui/lib/shared/dx/dx-data-source-options.model';
import { DeliveryProduct } from 'src/app/delivery-product/DeliveryProduct';
import LoadOptions from 'devextreme/data/load_options';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { downloadBlob } from 'src/helpers/download';
import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class DeliveryProductService {

    constructor(
        private readonly dxService: EciDxService,
        private readonly http: HttpClient,
        private readonly errorHandler: ErrorHandler,
    ) {
    }

    /**
     * Returns the Delivery Products DataSource.
     *
     * @returns dx-data source
     */
    createDeliveryProductDataSource(): DataSource {
        return this.dxService.createEditableDataSource(
            'api/delivery-products',
            'erpNumber',
        );
    }

    createDeliveryProductSearchesDataSource(): DataSource {
        return this.dxService.createEditableDataSource(
            'api/delivery-products/searches',
            'id',
        );
    }

    /**
     * Update days for delivery for all the articles
     *
     * @param selectedArticleErpNumbers
     * @param deselectedArticleErpNumbers
     * @param selectAll
     * @param loadOptionsFilter
     * @param daysForDelivery
     * @param countryId
     * @param roles
     */
    updateDeliveryTimeForSelectedArticles(selectedArticleErpNumbers: any, deselectedArticleErpNumbers: any,
                                          selectAll: any, loadOptionsFilter: any, daysForDelivery: string,
                                          countryId: string, file: File, roles: any): Observable<HttpResponse<object>> {
        const formData: FormData = new FormData();
        if (file != null) {
            formData.append('file', file, file.name);
        }
        formData.append('selectedArticles', selectedArticleErpNumbers);
        formData.append('deselectedArticles', deselectedArticleErpNumbers);
        formData.append('selectAll', selectAll);
        formData.append('loadOptionsFilter', loadOptionsFilter);
        formData.append('daysForDelivery', daysForDelivery);
        formData.append('countryId', countryId);
        formData.append('roles', roles);

        return this.http.post('api/delivery-products/update-days-for-delivery', formData,
            { observe: 'response' });
    }

    createDataSource(
        file: File,
        countryId: string,
        additionalOptions: EciDxDataSourceOptions = {},
        headers?: string | { [name: string]: string | string[] },
    ): DataSource {
        const options: EciDxDataSourceOptions = {
            key: 'erpNumber',
            load: loadOptions => this.query<DeliveryProduct[]>('api/delivery-products/search-from-file', file, loadOptions, countryId, headers),
            update: (key, value: DeliveryProduct) => this.updateElement<DeliveryProduct>('api/delivery-products', key, value),
            remove: key => this.removeElement('api/delivery-products', key),
        };

        return new DataSource(options);
    }

    saveDeliveryProductSearch(searchName: string, searchValue: string, gridState: string, user: string): Observable<HttpResponse<object>> {

        const formData: FormData = new FormData();
        formData.append('searchName', searchName);
        formData.append('filterValue', JSON.stringify(searchValue));
        formData.append('gridState', JSON.stringify(gridState));
        formData.append('user', user);

        return this.http.post('api/delivery-products/searches', formData,
            { observe: 'response' });
    }

    query<TData>(
        url: string,
        file: File,
        loadOptions: LoadOptions.LoadOptions,
        countryId: string,
        headers?: string | { [name: string]: string | string[] },
    ): Promise<any> {
        this.convertSearchToFilter(loadOptions);

        let params: HttpParams = new HttpParams({
            encoder: new EciQueryEncoder(),
        });

        Object.keys(loadOptions).forEach(key => {
            if (loadOptions[key] != null) {
                params = params.append(
                    key,
                    JSON.stringify(loadOptions[key]),
                );
            }
        });

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('countryId', countryId);

        return this.http.post<DeliveryProduct[]>(url, formData,
            {
                params,
                headers: new HttpHeaders(headers),
                observe: 'response', responseType: 'json',
            })
            .pipe(
                map(response => {
                    const totalCount = Number.parseInt(
                        response.headers.get('X-Total-Count'),
                    );
                    const data = response.body;
                    return { totalCount, data } as EciDxQueryResult<DeliveryProduct>;
                }),
                catchError(error => {
                    this.errorHandler.handleError(error);

                    return of(this.buildEmptyEciDxQueryResult());
                }),
            ).toPromise();
    }

    /**
     * Downloads the delivery products as CSV
     *
     */
    downloadCsv(country: any, filter: any, selectedColumns: any, file: any): void {
        if (typeof file === 'undefined') {
            file = null;
        }
        const formData: FormData = new FormData();
        formData.append('countryId', country);
        formData.append('loadOptionsFilter', filter);
        formData.append('selectedColumnsForCsv', selectedColumns);
        formData.append('file', file !== null ? file : new File([], 'file'));

        this.http.post('api/delivery-products/csv', formData,
            { responseType: 'blob' }).subscribe((value: Blob) => {
            downloadBlob(value, `products_${country}_${formatDate(new Date(), 'yyyy-MM-dd_hh-mm-ss', 'en-US')}.csv`);
        });
    }

    private convertSearchToFilter(loadOptions: LoadOptions.LoadOptions) {
        const { searchExpr, searchOperation, searchValue } = loadOptions;

        // If one of the required search parameters is not set, skip processing them at all
        if (
            searchExpr == null ||
            searchOperation == null ||
            searchValue == null
        ) {
            return;
        }

        // Build filters based on given search expressions and replace existing filters
        loadOptions.filter = [];
        (Array.isArray(searchExpr) ? searchExpr : [searchExpr]).forEach(
            (expr, index) => {
                if (index !== 0) {
                    loadOptions.filter.push('or');
                }

                loadOptions.filter.push([expr, searchOperation, searchValue]);
            },
        );
    }

    private buildEmptyEciDxQueryResult(): EciDxQueryResult<any> {
        return { data: [], totalCount: 0 };
    }

    private updateElement<T = any>(
        url: string,
        key: string,
        data: T,
    ): Promise<T> {
        return this.http.put<T>(`${url}/${key}`, data)
            .pipe(catchError(error => {
                this.errorHandler.handleError(error);

                return throwError(error);
            }))
            .toPromise();
    }

    private removeElement(url: string, key: string): Promise<number> {
        return this.http.delete<number>(`${url}/${key}`)
            .pipe(catchError(error => {
                this.errorHandler.handleError(error);

                return throwError(error);
            }))
            .toPromise();
    }
}
