import { ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { MasterArticleJobService } from './master-article-job.service';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CountryService } from 'src/helpers/CountryService';
import notify from 'devextreme/ui/notify';
import DxButton from 'devextreme/ui/button';
import { confirm } from 'devextreme/ui/dialog';
import FileUploader from 'devextreme/ui/file_uploader';
import { DataGridUtilService } from '../service/DataGridUtilService';

@Component({
    selector: 'vltc-master-article-jobs',
    templateUrl: './master-article-job.component.html',
    styleUrls: ['./master-article-job.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MasterArticleJobComponent implements OnInit {

    @ViewChild(DxDataGridComponent) masterArticleGrid: DxDataGridComponent;
    @ViewChild(DxDataGridComponent) productsDataGrid: DxDataGridComponent;
    @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>;

    masterArticleDatasource: DataSource;
    country: any;
    erpNumber = '';
    fileToUpload: File = null;
    fileName: string[] = [];
    filterValue = 'Product updated';
    deliveryTime = 0;
    selectedRows: string[] = [];
    selectedArticlesMapByPage = new Map<number, string[]>();
    deselectedArticlesMapByPage = new Map<number, string[]>();
    isSelectAllEnabled = false;
    fileUploader: FileUploader;
    searchName = '';
    searchValue = '';
    gridState = '';

    constructor(
        private readonly masterArticleJobService: MasterArticleJobService,
        public translateService: EciTenantAwareLanguageService,
        private readonly countryService: CountryService,
        public dataGridUtilService: DataGridUtilService,
    ) {
        this.countryService.getCountry.subscribe(c => this.country = c);
    }

    ngOnInit() {
        this.masterArticleDatasource = this.masterArticleJobService.createEditableDataSource();
    }

    onNewEntry(event) {
        event.data.active = true;
    }

    onEditorPreparing(event) {
        if (event.parentType === 'headerRow' && event.command === 'select') {
            event.editorElement.remove();
        }

        if (event.parentType === 'filterRow' && event.dataField === 'itemGroupCategory') {
            event.cancel = true;
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
                location: 'before',
                template: 'totalGroupCount',
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    width: 136,
                    text: this.isSelectAllEnabled ? this.translateService.get('app.deliveryProduct.toolbar.deselectAll')
                        : this.translateService.get('app.deliveryProduct.toolbar.selectAll'),
                    onClick: this.selectAllArticles.bind(this),
                    elementAttr: {
                        id: 'selectAllButtonId',
                    },
                },

            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    width: 90,
                    text: this.translateService.get('app.masterArticleJob.toolbar.delete'),
                    type: 'success',
                    onClick: this.deleteMasterArticleJobs.bind(this),
                },
            });
    }

    successNotification() {
        notify(this.translateService.get('global.success'), 'success', 600);
    }

    onRowPrepared(event) {
    }

    uploadFile(event: any) {
        const files = event.value;
        if (files) {
            this.fileToUpload = files[0];
            console.log('FileUpload -> files ', this.fileToUpload);
        }
    }

    updateDeliveryTimeForArticles() {
        const result = confirm('<i>' +
            this.translateService.get('app.masterArticleJob.confirmationDialog.confirmationMessage')
            + '</i>',
            this.translateService.get('app.masterArticleJob.confirmationDialog.confirmChanges'));
        result.then(dialogResult => {
            if (dialogResult) {
                this.masterArticleJobService.updateArticlesFromFile(this.country.id, this.fileToUpload)
                    .subscribe(response => {
                        this.masterArticleGrid.instance.refresh().then(r => {
                        });
                        if (response.body.valueOf() === -1) {
                            notify(this.translateService
                                    .get('app.masterArticleJob.confirmationDialog.inputError'),
                                'error', 2000);
                        } else {
                            notify(response.body + ' ' +
                                this.translateService
                                    .get('app.deliveryProduct.confirmationDialog.articlesWereSaved'),
                                'success', 1000);
                        }
                    });
            }
        });
    }

    deleteMasterArticleJobs() {
        if (Array.isArray(this.selectedRows) && this.selectedRows.length > 0) {
            const result = confirm('<i>' +
                this.translateService.get('app.masterArticleJob.confirmationDialog.deleteConfirmationMessage') +
                ' ' + this.getTotalRowCount() + ' ' +
                this.translateService.get('app.masterArticleJob.confirmationDialog.articles') + ' ?</i>',
                this.translateService.get('app.masterArticleJob.confirmationDialog.confirmChanges'));
            result.then(dialogResult => {
                if (dialogResult) {
                    this.masterArticleGrid.instance.beginCustomLoading(this.translateService.get('app.deliveryProduct.confirmationDialog.savingArticles'));
                    this.masterArticleJobService.deleteMasterArticleJobs(this.convertMapValuesToArray(this.selectedArticlesMapByPage),
                        this.convertMapValuesToArray(this.deselectedArticlesMapByPage), this.isSelectAllEnabled,
                        typeof this.masterArticleGrid.instance.getCombinedFilter() !== 'undefined' ?
                            JSON.stringify(this.masterArticleGrid.instance.getCombinedFilter()) : '[]',
                        this.country.id, this.fileToUpload)
                        .subscribe(response => {
                            notify(response.body + ' ' +
                                this.translateService.get('app.deliveryProduct.confirmationDialog.articlesWereSaved'),
                                'success', 1000);
                            this.updateElementsAfterUpdate();
                        });
                }
            });
        }
    }

    getFileUploader(): FileUploader {
        const fileUploaderElement = document.getElementById('fileUploaderId');
        return FileUploader.getInstance(fileUploaderElement) as FileUploader;
    }

    resetFileUploader() {
        this.getFileUploader().reset();
        this.resetDatasource();
    }

    selectAllArticles(e) {
        if (this.isSelectAllEnabled === false) {
            this.selectedArticlesMapByPage.clear();
            this.deselectedArticlesMapByPage.clear();
            this.selectAllVisibleRows();
            this.isSelectAllEnabled = true;
            e.component.option({
                text: this.translateService.get('app.deliveryProduct.toolbar.deselectAll'),
            });

        } else {
            this.isSelectAllEnabled = false;
            this.selectedArticlesMapByPage.clear();
            this.deselectedArticlesMapByPage.clear();
            this.masterArticleGrid.instance.clearSelection();
            e.component.option({
                text: this.translateService.get('app.deliveryProduct.toolbar.selectAll'),
            });
        }
    }

    getTotalRowCount(): number {
        let totalRows = 0;
        if (this.isSelectAllEnabled) {
            totalRows = this.masterArticleGrid.instance.totalCount() - this.convertMapValuesToArray(this.deselectedArticlesMapByPage).length;
        } else {
            totalRows = this.masterArticleGrid.instance.getSelectedRowKeys().length;
        }
        return totalRows;
    }

    onOptionChanged(e) {
        if (e.fullName === 'paging.pageIndex') {
            console.log('onOptionChanged');
        }
    }

    onSelectionChanged() {
        this.selectedArticlesMapByPage.set(this.masterArticleGrid.instance.pageIndex(),
            this.masterArticleGrid.instance.getVisibleRows().filter(r => r.isSelected).map(m => m.key));
        this.deselectedArticlesMapByPage.set(this.masterArticleGrid.instance.pageIndex(),
            this.masterArticleGrid.instance.getVisibleRows().filter(r => !r.isSelected).map(m => m.key));
    }

    selectAllVisibleRows() {
        const visibleRows = this.masterArticleGrid.instance.getVisibleRows().map(r => r.key);
        this.masterArticleGrid.instance.selectRows(visibleRows, true).then(r => {
        });
    }

    convertMapValuesToArray(map: Map<number, string[]>): string[] {
        const result: string[] = [];
        map.forEach((value: string[], key: number) => {
            value.forEach(value1 => result.push(value1));
        });
        return result;
    }

    onContentReady() {
        if (this.isSelectAllEnabled === true) {
            const selectedArticlesPerPage = this.selectedArticlesMapByPage.get(this.masterArticleGrid.instance.pageIndex());
            if (selectedArticlesPerPage !== undefined && selectedArticlesPerPage.length > 0) {
                this.masterArticleGrid.instance.selectRows(selectedArticlesPerPage, true).then(r => {
                });
            } else {
                this.selectAllVisibleRows();
            }
        }
        if (this.isSelectAllEnabled === false) {
            const selectedArticlesPerPage = this.selectedArticlesMapByPage.get(this.masterArticleGrid.instance.pageIndex());
            if (selectedArticlesPerPage !== undefined && selectedArticlesPerPage.length > 0) {
                this.masterArticleGrid.instance.selectRows(selectedArticlesPerPage, true).then(r => {
                });
            }
        }
    }

    updateElementsAfterUpdate() {
        this.isSelectAllEnabled = false;
        this.deliveryTime = 0;
        this.selectedRows.length = 0;
        this.selectedArticlesMapByPage.clear();
        this.deselectedArticlesMapByPage.clear();
        this.masterArticleGrid.instance.clearSelection();
        this.masterArticleGrid.instance.refresh().then(r => {
        });
        this.masterArticleGrid.instance.endCustomLoading();
        // there's no two way binding for toolbar elements
        const buttonElement = document.getElementById('selectAllButtonId');
        DxButton.getInstance(buttonElement).option('text', this.translateService.get('app.deliveryProduct.toolbar.selectAll'));
    }

    changeDatasource() {
        try {
            this.masterArticleDatasource = null;
            this.masterArticleDatasource = this.masterArticleJobService
                .createDataSource(this.fileToUpload, this.country.id);
            this.masterArticleGrid.instance.endCustomLoading();
            this.masterArticleGrid.instance.repaint();
        } catch (e) {
            console.error('Error in changing data source ' + e);
        }
    }

    resetDatasource() {
        try {
            this.masterArticleDatasource = null;
            this.masterArticleDatasource = this.masterArticleJobService.createEditableDataSource();
            this.masterArticleGrid.instance.endCustomLoading();
            this.masterArticleGrid.instance.repaint();

        } catch (e) {
            console.error('Error in resetting data source ' + e);
        }
    }

}
